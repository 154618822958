import axios from "@/libs/api.request.js"

const dataApi = {
	/**
	 * 获取用户信息（检查登录状态）
	 * @param   
	 * @param 
	 */
	getUserInfo(data) {
		return axios.request({
			url: "/api/stud",
			params: data,
			method: "get"
		});
	},
	/**
	 * 获取用户微信信息（检查微信授权）
	 * @param   
	 * @param 
	 */
	getWxInfo(data) {
		return axios.request({
			url: "/api/wxInfo",
			params: data,
			method: "get"
		});
	},
	/**
	 * 获取授权地址
	 * @param { url } 页面当前地址
	 * @param 
	 */
	getAuth(data) {
		return axios.request({
			url: "/api/login/auth",
			params: data,
			method: "get"
		});
	},
	/**
	 * 登录
	 * @param { code } 微信授权成功之后URL上
	 * @param 
	 */
	login(data) {
		return axios.request({
			url: "/api/login",
			params: data,
			method: "get"
		});
	},
	/**
	 * 学生报名
	 * @param 
	 * @param 
	 */
	apply(data) {
		return axios.request({
			url: "/api/stud/apply",
			data,
			method: "post"
		});
	},
	/**
	 * 绑定学生信息
	 * @param 
	 * @param 
	 */
	postBindInfo(data) {
		return axios.request({
			url: "/api/stud",
			data,
			method: "post"
		});
	},
	/**
	 * 获取绑定学生信息
	 * @param 
	 * @param 
	 */
	getBindInfo() {
		return axios.request({
			url: "/api/stud",
			method: "get"
		});
	},
	/**

	 * 检查是否可报名(即将废弃)
	 * @param 
	 * @param 
	 */
	checkApplyStatus(data) {
		return axios.request({
			url: "/api/stud/checkApply",
			params: data,
			method: "get"
		});
	},
	/**
	 * 清除绑定信息
	 * @param 
	 * @param 
	 */
	clearInfo(data) {
		return axios.request({
			url: "/api/clearBind",
			params: data,
			method: "get"
		});
	},
	/**
	 * 清除考试信息
	 * @param 
	 * @param 
	 */
	clearPaper(data) {
		return axios.request({
			url: "/api/clearPaper",
			params: data,
			method: "get"
		});
	},
	/**
	 * 获取题目
	 * @param 
	 * @param 
	 */
	getPaper(data) {
		return axios.request({
			url: "/api/question",
			params: data,
			method: "get"
		});
	},
	/**
	 * 提交试卷
	 * @param 
	 * @param 
	 */
	commitPaper(data) {
		return axios.request({
			url: "/api/submitpaper",
			data,
			method: "post"
		});
	},
	/**
	 * 心跳
	 * @param 
	 * @param 
	 */
	heart(data) {
		return axios.request({
			url: "/api/heart",
			data,
			method: "post"
		});
	},
	/**
	 * 答题剩余时间
	 * @param 
	 * @param 
	 */
	getTime(data) {
		return axios.request({
			url: "/api/lastTime",
			params: data,
			method: "get"
		});
	},
	/**
	 * 查询状态,用户信息。比赛状态
	 * @param 
	 * @param 
	 */
	checkState(data) {
		return axios.request({
			url: "/api/home",
			params: data,
			method: "get"
		});
	},
	/**
	 * 修改时间（测试接口）
	 * @param 
	 * @param 
	 */
	setNowTime(data) {
		return axios.request({
			url: "/api/time",
			data,
			method: "post"
		});
	},
	/**
	 * 测试-获取服务器时间
	 * @param 
	 * @param 
	 */
	getNowTime(data) {
		return axios.request({
			url: "/api/time",
			params: data,
			method: "get"
		});
	},
	/**
	 * 读取时间（测试接口）
	 * @param 
	 * @param 
	 */
	getNowTime(data) {
		return axios.request({
			url: "/api/time",
			params: data,
			method: "get"
		});
	},
	/**
	 * 微信分享
	 * @param 
	 * @param 
	 */
	wxShare(data) {
		return axios.request({
			url: "/api/jsConfig",
			params: data,
			method: "get"
		});
	},
	/**
	 * 测试接口清除用户信息
	 * @param 
	 * @param 
	 */
	clearInfo(data) {
		return axios.request({
			url: "/api/clean/1/1",
			params: data,
			method: "get"
		});
	},
	/**
	 * 微信分享
	 * @param 
	 * @param 
	 */
	getStatus(data) {
		return axios.request({
			url: "/api/button",
			params: data,
			method: "get"
		});
	},
	/**
	 * 查看成绩列表
	 * @param 
	 * @param 
	 */
	getScore(data) {
		return axios.request({
			url: "/api/transcriptList",
			params: data,
			method: "get"
		});
	},
	/**
	 * 查看成绩(完成测评模块)
	 * @param 
	 * @param 
	 */
	getScoreCompleted(data) {
		return axios.request({
			url: "/api/transcript",
			params: data,
			method: "get"
		});
	},

}


export default dataApi
